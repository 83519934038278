<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="24" :xs="24" class="elel">
						<el-button type="primary" @click="dialogAdd = true">新增</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="refreshCache">刷新缓存</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="previewConfig">预览</el-button>
						<div class="readbox">
							<el-tag size="large">查询是否可用：</el-tag>
							<el-radio-group v-model="status" @change="radioes">
								<el-radio label="" border>全部</el-radio>
								<el-radio :label="1" border>是</el-radio>
								<el-radio :label="0" border>否</el-radio>
							</el-radio-group>
						</div>
					</el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border stripe>
					<el-table-column prop="id" label="主键id"></el-table-column>
					<el-table-column prop="money" label="余额门槛"></el-table-column>
					<el-table-column prop="beatStageRate" label="过关奖励比例"></el-table-column>
					<el-table-column prop="prizeWheelRate" label="转盘奖励比例"></el-table-column>
					<el-table-column prop="minMoney" label="最低奖励金额"></el-table-column>
					<el-table-column label="是否可用">
						<template v-slot="scope">
							<el-switch
								@change="switchClick(scope.row)"
								v-model="scope.row.status"
								class="mb-2"
								style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
								:active-value="1"
								:inactive-value="0"
								active-text="是"
								inactive-text="否"
							/>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column label="操作" align="center">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
							<el-button size="small" type="primary" plain @click="clcdelete(scope.row)">删除</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
				<!-- 新增-配置 -->
				<el-dialog v-model="dialogAdd" title="新增配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="140px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="金额" prop="money"><el-input-number v-model="ruleForm.money" :step="0.01" :min="0.01" placeholder="请输入金额" /></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="过关奖励比例" prop="beatStageRate">
									<el-input-number v-model="ruleForm.beatStageRate" :step="0.01" :min="0.01" placeholder="请输入过关奖励比例" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="转盘奖励比例" prop="prizeWheelRate">
									<el-input-number v-model="ruleForm.prizeWheelRate" :step="0.01" :min="0.01" placeholder="请输入转盘奖励比例" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="最低奖励金额" prop="minMoney">
									<el-input-number v-model="ruleForm.minMoney" :step="0.01" :min="0.01" placeholder="请输入最低奖励金额" />
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改-配置 -->
				<el-dialog v-model="dialogEdit" title="修改配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="140px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="金额" prop="money">
									<el-input-number v-model="EditruleForm.money" :step="0.01" :min="0.01" placeholder="请输入金额" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="过关奖励比例" prop="beatStageRate">
									<el-input-number v-model="EditruleForm.beatStageRate" :step="0.01" :min="0.01" placeholder="请输入过关奖励比例" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="转盘奖励比例" prop="prizeWheelRate">
									<el-input-number v-model="EditruleForm.prizeWheelRate" :step="0.01" :min="0.01" placeholder="请输入转盘奖励比例" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="最低奖励金额" prop="minMoney">
									<el-input-number v-model="EditruleForm.minMoney" :step="0.01" :min="0.01" placeholder="请输入最低奖励金额" />
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 预览过关奖励设置 -->
				<el-dialog v-model="dialogConfig" title="预览" width="30%" :fullscreen="fullscreenshow">
					<el-scrollbar height="400px">
						<el-space direction="vertical">
							<p v-for="(item, index) in previewData" :key="index">{{ item }}</p>
						</el-space>
					</el-scrollbar>
					<template #footer>
						<span class="dialog-footer"><el-button @click="dialogConfig = false">取消</el-button></span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'beatStageRewardConfig',
	data() {
		return {
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			status: '',
			// 新增配置
			dialogAdd: false,
			ruleForm: {
				money: null,
				beatStageRate: null,
				prizeWheelRate: null,
				minMoney: null
			},
			rules: {
				money: [{ required: true, message: '请输入金额', trigger: 'blur' }],
				beatStageRate: [{ required: true, message: '请输入过关奖励比例', trigger: 'blur' }],
				prizeWheelRate: [{ required: true, message: '请输入转盘奖励比例', trigger: 'blur' }],
				minMoney: [{ required: true, message: '请输入最低奖励金额', trigger: 'blur' }]
			},
			// 编辑
			dialogEdit: false,
			EditruleForm: {
				id: '',
				money: '',
				beatStageRate: '',
				prizeWheelRate: '',
				minMoney: ''
			},
			// 预览过关奖励
			dialogConfig: false,
			previewData: []
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 获取过关奖励
		this.beatStConfig();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取过关奖励
				this.beatStConfig();
			}
		}
	},
	methods: {
		// 获取过关奖励
		beatStConfig() {
			this.serloading = true;
			this.$HTTP.post(
				'beatStageRewardConfig/query',
				{
					productId: this.productId,
					status: this.status,
					pageNumber: this.currentPage,
					pageSize: this.pageSize
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		// 刷新缓存
		refreshCache() {
			this.serloading = true;
			this.$HTTP.post(
				'beatStageRewardConfig/refreshCache',
				{
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.serloading = false;
							this.$message.success(response.data.msg);
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		//预览配置
		previewConfig() {
			this.$HTTP.post(
				'beatStageRewardConfig/preview',
				{
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.previewData = response.data.data;
							this.dialogConfig = true;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		//查新是否可用
		radioes() {
			this.currentPage = 1;
			this.beatStConfig();
		},
		// 是否可用
		switchClick(row) {
			this.$HTTP.post(
				'beatStageRewardConfig/updateStatus',
				{
					id: row.id,
					status: row.status
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							// 获取过关奖励
							this.beatStConfig();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 新增-配置
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'beatStageRewardConfig/add',
						{
							money: this.ruleForm.money,
							beatStageRate: this.ruleForm.beatStageRate,
							prizeWheelRate: this.ruleForm.prizeWheelRate,
							minMoney: this.ruleForm.minMoney,
							productId: this.productId
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogAdd = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
									// 获取过关奖励
									this.beatStConfig();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 编辑
		clcedit(row) {
			this.EditruleForm.money = row.money;
			this.EditruleForm.beatStageRate = row.beatStageRate;
			this.EditruleForm.prizeWheelRate = row.prizeWheelRate;
			this.EditruleForm.minMoney = row.minMoney;
			this.EditruleForm.id = row.id;
			this.dialogEdit = true;
		},
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'beatStageRewardConfig/update',
						{
							id: this.EditruleForm.id,
							beatStageRate: this.EditruleForm.beatStageRate,
							money: this.EditruleForm.money,
							prizeWheelRate: this.EditruleForm.prizeWheelRate,
							minMoney: this.EditruleForm.minMoney
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取过关奖励
									this.beatStConfig();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 删除
		clcdelete(row) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$HTTP.post(
						'beatStageRewardConfig/delete',
						{
							id: row.id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取过关奖励
									this.beatStConfig();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取过关奖励
			this.beatStConfig();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取过关奖励
			this.beatStConfig();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-input-number {
	width: 100%;
}
.readbox {
	display: flex;
	margin-left: 20px;
	align-items: center;
	span {
		margin-right: 10px;
		font-size: 16px;
	}
}
.elel {
	display: flex;
	align-items: center;
}
</style>
